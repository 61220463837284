.fa-check-circle {
  @apply text-green;
}

.fa-clock {
  @apply text-gray;
}

.fa-exclamation-circle {
  @apply text-orange;
}
