@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap');

// @use 'sass:meta';

@layer tailwind-base, primeng, tailwind-components;

/**
 * Inject tailwind files inside layers so that primeng can be ordered correctly in the css layers. tailwind v4 may add layers and this will need to be looked at again.
 * https://github.com/tailwindlabs/tailwindcss/discussions/6694#discussioncomment-4716568
 */

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-components {
  @tailwind components;
}
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
@import 'buttons';
@import 'forms';
@import 'widget';
@import 'dialog';
@import 'icons';

html,
body {
  @apply h-full;
}
body {
  @apply m-0 font-roboto;
  font-family: 'Open Sans', 'Roboto Slab', sans-serif;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;

  .page-heading {
    @apply text-2xl font-bold leading-7 font-robotoSlabBold;
  }
}

input:focus {
  outline: none !important;
  box-shadow: none;
  --tw-ring-color: transparent !important;
  --tw-ring-opacity: 0;
}
