.lfx-btn {
  @apply rounded-md box-border text-center float-right px-6 py-3 text-13;

  &.primary {
    @apply bg-primary text-white hover:bg-opacity-90;

    &:disabled {
      @apply bg-gray-300 text-white;
    }
  }

  &.white {
    @apply bg-white border border-solid border-gray;
  }
}
