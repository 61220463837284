.lfx-radio {
  @apply border border-solid border-gray checked:bg-white checked:hover:bg-white checked:active:bg-white;
  @apply checked:focus:bg-white focus:bg-white;
  &:checked,
  &:hover:checked {
    @apply border border-solid border-gray;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='gray' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  }
}

input,
.p-inputtext,
textarea {
  @apply border border-solid border-gray-300 font-sans;

  &:not([type='radio']) {
    @apply rounded-md py-2;
  }

  &:focus {
    @apply border border-solid border-gray-300;
  }

  &.p-inputtext {
    @apply border border-solid border-gray-300 rounded-md py-1 text-13;

    &:focus {
      @apply border border-solid border-gray-300;
    }

    &.p-inputtext-sm {
      @apply py-1 text-xs;
    }
  }
}

.p-inputgroup {
  .p-button {
    &.p-button-icon-only {
      @apply w-7 p-1;
    }
  }
}

.file-upload {
  @apply border border-solid border-gray-300 rounded-md py-1 px-2 text-13 justify-between gap-3 leading-6 flex flex-row-reverse hover:border-blue-500;

  &.error {
    @apply border-red;
  }

  label {
    @apply leading-6 font-normal text-13 font-sans;

    i {
      @apply text-blue-light cursor-pointer;
    }
  }

  .file-delete {
    i {
      @apply text-red cursor-pointer;
    }
  }

  .file-name {
    @apply text-ellipsis overflow-hidden whitespace-nowrap cursor-text;

    span {
      @apply text-13 font-normal leading-6;
    }
  }
}

.form-control {
  @apply flex flex-col gap-2 relative;

  label {
    @apply text-sm font-sans font-bold;

    .required {
      @apply text-red px-1;
    }
  }

  span {
    @apply text-sm;

    a {
      @apply text-blue-light;
    }
  }

  a {
    @apply text-blue-light;
  }
}
