.widget {
  @apply border border-solid border-gray-e0e rounded-md pt-3;

  .widget-container {
    @apply flex flex-col gap-4 px-4;
  }

  h2 {
    @apply font-robotoSlabBold px-4 my-2 text-lg;
  }
}

.project-details-grid {
  @apply grid grid-cols-1 lg:grid-cols-3 gap-9;
}

.project-detail {
  @apply flex flex-col gap-2 relative;

  label {
    @apply text-sm font-sans font-bold;
  }

  span {
    @apply text-sm;

    a {
      @apply text-blue-light;
    }
  }

  a {
    @apply text-blue-light;
  }
}

.switch-active {
  p-inputSwitch .p-inputswitch:not(.p-disabled).p-inputswitch .p-inputswitch-slider:before {
    --tw-bg-opacity: 1;
    background-color: rgb(0 148 255 / var(--tw-bg-opacity));
  }

  p-inputSwitch .p-inputswitch:not(.p-disabled).p-inputswitch .p-inputswitch-slider {
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    background: #fff !important;
  }
}
